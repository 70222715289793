body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header2 {
  background: #001529;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header2 .home-page {
  padding: 0 24px;
}
.header2-logo {
  display: none;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header2-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header2-logo a {
  display: block;
}
.header2-menu {
  display: none;
  float: right;
}
.header2-menu > .menu-item {
  line-height: 62px;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header2-menu > .menu-item.active,
.header2-menu > .menu-item:hover {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
@media screen and (max-width: 767px) {
  .header2-logo {
    z-index: 101;
  }
  .header2.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header2-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-menu .menu-item {
    display: block;
    width: 100%;
    padding: 0 24px;
    border-bottom: none;
    line-height: 40px;
    height: 40px;
    margin: 4px 0 8px;
  }
  .header2-menu .menu-item.active,
  .header2-menu .menu-item:hover {
    border: none;
    background: #1890ff;
    color: #fff;
  }
  .header2-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header2-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-mobile-menu :first-child {
    margin-top: 0;
  }
  .header2 .open {
    height: auto;
  }
  .header2 .open .header2-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header2 .open .header2-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header2 .open .header2-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header2 .open .header2-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner3 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/banner.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner3 .banner3-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  max-width: 845px;
  height: 500px;
  width: 80%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
.banner3 .banner3-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner3 .banner3-slogan {
  font-size: 68px;
  line-height: 80px;
  text-indent: 2px;
  font-weight: 600;
  margin: 26px auto 38px;
  overflow: hidden;
  color: #0fc6fe;
}
.banner3 .banner3-name-en {
  display: block;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
}
.banner3 .banner3-name {
  font-size: 24px;
  margin-bottom: 45px;
  overflow: hidden;
  opacity: 0.8;
}
.banner3 .banner3-button {
  display: block;
  margin: 72px auto 0;
  background: #034365;
  background: linear-gradient(to right, #034365 0%, #001b33 100%);
  box-shadow: 0 8px 16px #0a52ab;
  border: none;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 132px;
  line-height: 42px;
  height: 42px;
  border-radius: 42px;
}
.banner3 .banner3-time {
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .banner3 {
    background-attachment: inherit;
  }
  .banner3 .banner3-text-wrapper {
    width: 90%;
    height: 50%;
  }
  .banner3 .banner3-name-en {
    font-size: 12px;
  }
  .banner3 .banner3-slogan {
    font-size: 24px;
    line-height: 1.5;
    margin: 12px 0;
  }
  .banner3 .banner3-name {
    font-size: 14px;
  }
}
.content8-wrapper.home-page-wrapper {
  overflow: initial;
  width: calc(100% - 112px);
  min-height: 878px;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 32px 32px rgba(34, 94, 222, 0.08);
  background: #fff;
}
.content8-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
  overflow: hidden;
}
.content8-wrapper .content-wrapper {
  margin-top: 72px;
}
.content8-wrapper .content-wrapper .content8-block-wrapper {
  margin-bottom: 60px;
}
.content8-wrapper .content-wrapper .content8-block {
  width: 100%;
  max-width: 192px;
  margin: auto;
}
.content8-wrapper .content-wrapper .content8-img {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
.content8-wrapper .content-wrapper .content8-img img {
  width: 100%;
  display: block;
}
.content8-wrapper .content-wrapper .content8-img img[src=""] {
  background: linear-gradient(to top right, #d6defc, #fff);
  padding-bottom: 100%;
}
.content8-wrapper .content-wrapper .content8-title {
  font-size: 20px;
  color: #0d1a26;
  font-weight: 400;
  margin: 24px auto 8px;
  text-align: center;
  white-space: nowrap;
}
.content8-wrapper .content-wrapper .content8-content {
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  color: #697b8c;
}
@media screen and (max-width: 767px) {
  .content8-wrapper.home-page-wrapper {
    padding-bottom: 0;
    box-shadow: none;
    width: 100%;
  }
  .content8-wrapper.home-page-wrapper .about-image {
    display: none;
  }
  .content8-wrapper.home-page-wrapper .content8.home-page {
    margin: auto;
    padding-bottom: 0;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-block {
    max-width: 240px;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .dis-no {
    display: none;
  }
}
.contentVideo-wrapper.home-page-wrapper {
  overflow: initial;
  width: calc(100% - 112px);
  min-height: 878px;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 32px 32px rgba(34, 94, 222, 0.08);
  background: #fff;
}
.contentVideo-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
  overflow: hidden;
}
.contentVideo-wrapper .content-wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0px;
  gap: 0px;
  /* Gap between items */
  margin-top: 72px;
}
.contentVideo-wrapper .content-wrapper .contentVideo-block-wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(33.333% - 16px);
  margin-bottom: 60px;
}
.contentVideo-wrapper .content-wrapper .contentVideo-block {
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  margin: auto;
}
.contentVideo-wrapper .content-wrapper .contentVideo-img {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
.contentVideo-wrapper .content-wrapper .contentVideo-img img {
  width: 100%;
  display: block;
}
.contentVideo-wrapper .content-wrapper .contentVideo-img img[src=""] {
  background: linear-gradient(to top right, #d6defc, #fff);
  padding-bottom: 100%;
}
.contentVideo-wrapper .content-wrapper .contentVideo-title {
  font-size: 20px;
  color: #0d1a26;
  font-weight: 400;
  margin: 24px auto 8px;
  text-align: center;
  white-space: nowrap;
}
.contentVideo-wrapper .content-wrapper .contentVideo-content {
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  color: #697b8c;
}
@media screen and (max-width: 767px) {
  .contentVideo-wrapper.home-page-wrapper {
    padding-bottom: 0;
    box-shadow: none;
    width: 100%;
  }
  .contentVideo-wrapper.home-page-wrapper .contentVideo.home-page {
    margin: auto;
    padding-bottom: 0;
  }
  .contentVideo-wrapper.home-page-wrapper .content-wrapper {
    flex-direction: column;
  }
  .contentVideo-wrapper.home-page-wrapper .content-wrapper .contentVideo-block {
    max-width: 240px;
  }
}
.popup {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}
.popup-content {
  position: relative;
}
.popup-content video {
  max-width: 90vw;
  max-height: 80vh;
}
.close-btn {
  position: absolute;
  top: -50px;
  right: -18px;
  color: white;
  font-size: 40px;
  cursor: pointer;
}
.content9-wrapper {
  padding-bottom: 64px;
}
.content9-wrapper.home-page-wrapper {
  overflow: initial;
}
.content9-wrapper .content9 {
  min-height: 800px;
  padding: 64px 0 0;
}
.content9-wrapper .timeline {
  position: relative;
}
.content9-wrapper .timeline:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: calc(100% - 108px);
  margin: 34px 0;
  border-left: 2px #ebedf0 dashed;
}
.content9-wrapper .block-wrapper {
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  position: relative;
  margin-bottom: 70px;
  min-height: 160px;
}
.content9-wrapper .block-wrapper:last-child {
  margin-bottom: 0;
}
.content9-wrapper .image-wrapper,
.content9-wrapper .text-wrapper {
  width: 50%;
}
.content9-wrapper .image-wrapper {
  text-align: center;
}
.content9-wrapper .image-wrapper .block-img,
.content9-wrapper .image-wrapper .name-wrapper {
  float: right;
  clear: both;
  width: 262px;
  margin: auto;
}
.content9-wrapper .image-wrapper .block-img {
  height: 98px;
}
.content9-wrapper .image-wrapper .block-img img {
  height: 100%;
}
.content9-wrapper .image-wrapper .name-wrapper {
  margin: 16px 0 0;
}
.content9-wrapper .image-wrapper .name-wrapper .block-name {
  font-size: 14px;
  margin-bottom: 4px;
}
.content9-wrapper .image-wrapper .name-wrapper .block-post {
  font-size: 12px;
  color: #697b8c;
}
.content9-wrapper .text-wrapper {
  padding-left: 40px;
}
.content9-wrapper .text-wrapper .block-time {
  font-size: 12px;
  color: #697b8c;
  line-height: 18px;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-title {
  margin: 8px 0 12px;
  font-size: 14px;
  position: relative;
  min-height: 18px;
  text-align: left;
}
.content9-wrapper .text-wrapper .block-icon {
  position: absolute;
  left: -40px;
  transform: translateX(calc(-50% + 1px));
}
.content9-wrapper .text-wrapper .block-content {
  width: 300px;
  color: #314659;
  font-size: 12px;
  min-height: 18px;
}
@media screen and (max-width: 767px) {
  .content9-wrapper {
    padding-bottom: 0;
  }
  .content9-wrapper .content9 {
    padding: 64px 24px;
  }
  .content9-wrapper .timeline:before {
    left: 0;
    height: 100%;
    margin: 0;
  }
  .content9-wrapper .block-wrapper {
    display: block;
    min-height: 100px;
    margin-bottom: 54px;
  }
  .content9-wrapper .image-wrapper,
  .content9-wrapper .text-wrapper {
    width: 100%;
  }
  .content9-wrapper .image-wrapper .block-img {
    display: none;
  }
  .content9-wrapper .image-wrapper .name-wrapper {
    text-align: left;
    float: left;
    width: 100%;
    padding-left: 40px;
    margin: auto;
  }
  .content9-wrapper .image-wrapper .block-name,
  .content9-wrapper .image-wrapper .block-post {
    display: inline-block;
  }
  .content9-wrapper .image-wrapper .block-name {
    margin-right: 8px;
  }
  .content9-wrapper .text-wrapper .block-title {
    font-size: 16px;
  }
}
.content10-wrapper {
  height: 480px;
  background: url(https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/weizhi.png) no-repeat 50%;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content10-wrapper .icon-wrapper {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.content10-wrapper .icon-wrapper img {
  display: block;
}
.content10-wrapper .icon {
  position: relative;
  z-index: 1;
  animation: BeatAnim 2s ease-in-out infinite;
}
.content10-wrapper .icon-shadow {
  display: inline-block;
  position: relative;
  top: -12px;
  z-index: 0;
  animation: ScaleAnim 2s ease-in-out infinite;
  transform-origin: 50%;
}
.content10-wrapper .map-tip {
  position: absolute;
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(13, 26, 38, 0.12);
  left: 50%;
  top: 50%;
  margin-left: 30px;
  margin-top: -60px;
  font-size: 14px;
  z-index: 10;
  transform-origin: 0 50%;
  text-align: left;
}
.content10-wrapper .map-tip h2 {
  font-size: 16px;
  color: #0d1a26;
  margin-bottom: 8px;
}
@media screen and (max-width: 767px) {
  .content10-wrapper {
    padding-bottom: 0;
  }
}
@keyframes BeatAnim {
  0%,
  25%,
  35%,
  45% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-30px);
  }
  30% {
    transform: translateY(-15px);
  }
  40% {
    transform: translateY(-7px);
  }
}
@keyframes ScaleAnim {
  0%,
  25%,
  35%,
  45% {
    transform: scale(1);
  }
  15% {
    transform: scale(0.5);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.9);
  }
}
.content11-wrapper {
  height: 480px;
  background: url("https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/footer.jpg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 40px;
}
.content11-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content11-wrapper .button {
  box-shadow: 0 8px 16px #0554b7;
  background: linear-gradient(to right, #05cbff, #1e5aff) !important;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border: 0;
  border-radius: 21px;
  color: #fff;
  width: 128px;
  padding: 0 15px;
  display: inline-block;
  transition: transform 0.3s, box-shadow 0.3s;
}
.content11-wrapper .button:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 20px #0554b7;
}
.content11-wrapper .button:active {
  transform: translateY(4px);
  box-shadow: 0 4px 8px #0554b7;
}
.content11-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content11-wrapper {
    padding-bottom: 0;
  }
}
.content12-wrapper {
  background-color: #fafafa;
  min-height: 470px;
}
.content12-wrapper .content12 {
  padding: 64px 24px;
}
.content12-wrapper .content12 > p {
  text-align: center;
}
.content12-wrapper .img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content12-wrapper .img-wrapper .block {
  margin-bottom: 40px;
}
.content12-wrapper .img-wrapper .block .block-content {
  display: flex;
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: none;
  height: 64px;
  align-items: center;
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content12-wrapper .img-wrapper .block .block-content > span {
  width: 100%;
  display: block;
}
@media screen and (max-width: 767px) {
  .content12-wrapper {
    overflow: hidden;
  }
  .content12-wrapper .content12 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content12-wrapper .content12 ul li span {
    height: 168px;
  }
}
.footer2-wrapper {
  background-color: #0d1a26;
  height: 80px;
  overflow: hidden;
}
.footer2-wrapper .footer2 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
.footer2-wrapper .copyright {
  float: right;
}
.footer2-wrapper .copyright > * {
  display: inline-block;
}
.footer2-wrapper .copyright-logo {
  width: 40px;
  height: 80px;
  margin-right: 8px;
}
.footer2-wrapper .copyright-logo img {
  width: 100%;
}
.footer2-wrapper .copyright-line {
  padding: 0 12px;
  margin: 0 12px;
}
.footer2-wrapper .copyright .copyright-gov {
  cursor: pointer;
}
.footer2-wrapper .copyright .copyright-gov:hover {
  color: #ffffff;
}
.footer2-wrapper .copyright .copyright-gov:active {
  color: #ffffff;
}
.footer2-wrapper .links {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}
.footer2-wrapper .links a {
  height: 21px;
  display: inline-block;
  margin-right: 32px;
}
.footer2-wrapper .links a img {
  display: block;
}
@media screen and (max-width: 767px) {
  .footer2-wrapper .footer2 {
    font-size: 12px;
  }
  .footer2-wrapper .footer2.home-page {
    padding: 0;
  }
  .footer2-wrapper .footer2 > div {
    width: 90%;
    margin: auto;
  }
}
div > .templates-wrapper > .jrhtw9ph4a-editor_css {
  position: absolute;
  background-color: rgba(0, 21, 41, 0);
  box-shadow: none;
}
.home-page > .header2-menu > .active {
  color: #ffffff;
}
.home-page > .header2-menu > .menu-item:hover {
  color: #e0e7ff;
  border-bottom-color: #ffffff;
}

